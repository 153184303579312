import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProfileCard from "./ProfileCard";
import StoreList from "./StoreList";
import Modal from 'react-bootstrap/Modal';
import DynamicIcon from "../Common/DynamicIcon";
import { add_newStore_validation_schema } from "../../constants/formValidation";
import { set_last_save_store } from '../../redux/slices/StoreSlice';
import { Formik } from "formik";
import http from "../../services/https";

const initialState = {
	store_name: '',
	store_url: '',
	merchant_id: 0
}
const TopHeader = () => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false)
	const [reFetchStores, setReFetchStores] = useState(false);
	const { storeList, newStorePopup } = useSelector((store) => store.store);
	const { user } = useSelector((store) => store.auth);

	const handleSubmit = async (payload, setErrors, setSubmitting) => {
		try {
			let _response = await http({
				url: "/store",
				method: "POST",
				data: payload
			});
			dispatch(set_last_save_store(_response));
			setReFetchStores(true); 
			setShowModal(false);
		} catch ({ error }) {
			if (error.errors) {
				setErrors(error.errors);
			}
		} finally {
			setSubmitting(false);
		}
	}

	useEffect(() => {
		setShowModal(newStorePopup);
	}, [storeList, newStorePopup]);


	return (
		<>
			<div className="left-side-header">
				<h2 className="">Dashboard</h2>
				<StoreList reFetchStores={reFetchStores} setReFetchStores={(e) => setReFetchStores(e)} />
				<button className="add-new d-none" title="Add new store" onClick={() => setShowModal(true)}>+</button>
			</div>
			<div className="right-side-header">
				<p>{ user?.email }</p>
				<ProfileCard />
			</div>
			<Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" centered>
				<div className="user-modal custom-data">
					<div className="header d-flex justify-content-between align-items-center mb-3">
						<p className="title">{'Add New Stores'}</p>
						{!!storeList.length && 
						<span className="reset-style" onClick={() => setShowModal(false)}>
							<DynamicIcon name={"close"} />
						</span>
						}
					</div>
					<Formik
						initialValues={initialState}
						validationSchema={add_newStore_validation_schema}
						onSubmit={(values, { setErrors, setSubmitting }) => { handleSubmit(values, setErrors, setSubmitting) }}>
						{({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
							<form onSubmit={handleSubmit} autoComplete="true" className="row">
								<div className="col-12">
									<div className="field">
										<label htmlFor="store_name">Store Name</label>
										<input type="text" onChange={handleChange} values={values.store_name} name="store_name" placeholder="Enter store name" />
									</div>
									{errors.store_name && touched.store_name && <div className="err-text">{errors.store_name}</div>}
								</div>
								<div className="col-12 my-2">
									<div className="field">
										<label htmlFor="store_url">Store Url</label>
										<input type="text" onChange={handleChange} values={values.store_url} name="store_url" placeholder="Enter store url" />
									</div>
									{errors.store_url && touched.store_url && <div className="err-text">{errors.store_url}</div>}
								</div>
								<div className="col-12">
									<div className="field">
										<label htmlFor="merchant_id">Merchant id</label>
										<input type="text" onChange={handleChange} values={values.merchant_id} name="merchant_id" placeholder="Merchant ID" />
									</div>
									{errors.merchant_id && touched.merchant_id && <div className="err-text">{errors.merchant_id}</div>}
								</div>
								<div className="col-12 invite">
									<button type="submit">Submit</button>
								</div>
							</form>
						)}
					</Formik>

				</div>
			</Modal>
		</>
	);
};

export default TopHeader;
