import { createSlice } from '@reduxjs/toolkit';

const OrderSlice = createSlice({
	name: 'order',
	initialState: {
		filters: {},
		orders: [],
		statuses: {},
		totalPages: 0,
		payment_methods: [],
		page_no:1,
		limit:20,
	},
	reducers: {
		set_Order_State: (state, action) => {
			state.filters = action.payload?.filters || {};
			state.orders = action.payload?.orders || [];
			state.statuses = action.payload?.statuses || {};
			state.totalPages = action.payload?.totalPages || 0;
		},
		set_order: (state, action) => {
			state.orders = action.payload;
		},
		set_status: (state, action) => {
			state.statuses = action.payload;
		},
		set_payment_method: (state, action) => {
			state.payment_methods = action.payload;
		},
	},
});

export const { set_Order_State, set_order, set_status, set_payment_method } =
	OrderSlice.actions;
export default OrderSlice.reducer;
